<template>
  <v-card >
    <v-card-title class="headline cabecalho-modal" primary-title>
      <small> {{titulo}} Imagem</small>
    </v-card-title>

    <v-card-text style="padding-top: 0px;padding-bottom: 0px;">
      <!--<v-alert :value="alertClose" color="error" icon="warning" variant="outlined" dismissible>-->
        <!--{{mensagem}}-->
      <!--</v-alert>-->

      <!--<div v-show="vcropperProgress" class="text-xs-center">-->
        <!--<v-progress-circular indeterminate color="primary"></v-progress-circular>-->
      <!--</div>-->
      <!--<div :class="classe" id="divBack">-->
        <!--&lt;!&ndash;VERSAO 4.2.0&ndash;&gt;-->
        <!--&lt;!&ndash;<vue-cropper :style="{display: display} + 'height: 300px'" ref='cropper' :guides="true" :view-mode="1"&ndash;&gt;-->
                     <!--&lt;!&ndash;drag-mode="crop" :auto-crop-area="1" :background="true" :rotatable="true" :auto-crop="true"&ndash;&gt;-->
                     <!--&lt;!&ndash;:img="imgUrl" :src="imgSrc" :aspectRatio="aspRatio" id="imageCropper" :img-style="estiloCropper">&ndash;&gt;-->
        <!--&lt;!&ndash;</vue-cropper>&ndash;&gt;-->

      <v-row justify="end">
        <v-col cols="12">
          <div class="vuecropper">
            <vue-cropper ref='cropper' :guides="true" :view-mode="1" drag-mode="crop"
                         :auto-crop-area="1" :background="true" :rotatable="true" :auto-crop="true"
                         :img="imgUrl" :src="imgSrc" :aspectRatio="aspRatio" id="imageCropper"
                         :img-style="estiloCropper">
            </vue-cropper>
          </div>
        </v-col>
      </v-row>

      <!--</div>-->
    </v-card-text>
    <v-card-actions>
      <v-autocomplete v-model="aspecto" :items="aspectos" item-title="descricao" item-value="value"
                      name="asp" variant="outlined" density="compact" id="ratio"
                      label="Relação de aspecto:" persistent-hint return-object></v-autocomplete>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="cancel">Cancelar</v-btn>
      <v-btn color="primary" text @click="save">Cortar</v-btn>
    </v-card-actions>

    <!--<v-card-actions style="padding-top: 2%;padding-bottom: 0px; width: 100%">-->
      <!--<div v-if="isSvg">-->
        <!--<v-spacer></v-spacer>-->
        <!--<v-btn @click.native="SalvarSvg()"> Confirmar </v-btn>-->
        <!--<v-btn @click.native="Fechar()"><v-icon>mdi-close</v-icon>Cancelar</v-btn>-->
      <!--</div>-->

      <!--<div v-else style=" width: 100%">-->
        <!--<v-row style=" width: 100%">-->
          <!--<v-col cols="12" sm="6" md="4">-->
            <!--<v-radio-group v-model="checkbox" row>-->
              <!--<v-radio label="Recomendado" :value="ratio" v-if="ratio !== ''"></v-radio>-->
              <!--<v-radio label="12/16" value="12/16" v-show="_6_16"></v-radio>-->
              <!--<v-radio label="16/9" value="16/9" v-show="_16_9"></v-radio>-->
              <!--<v-radio label="4/3" value="4/3" v-show="_4_3"></v-radio>-->
              <!--<v-radio label="livre" value="Livre" v-show="livre"></v-radio>-->
            <!--</v-radio-group>-->
          <!--</v-col>-->
          <!--<v-col cols="12" sm="6" md="8">-->
            <!--<div style="text-align: right;">-->
              <!--<v-btn @click.native="cropImage" :loading="spinner"><v-icon>crop</v-icon>Cortar</v-btn>-->
              <!--<v-btn @click.native="Fechar()"><v-icon>mdi-close</v-icon>Cancelar</v-btn>-->
            <!--</div>-->
          <!--</v-col>-->
        <!--</v-row>-->
      <!--</div>-->
    <!--</v-card-actions>-->
  </v-card>
  <!--</div>-->
</template>

<script>
/* eslint-disable */
import VueCropper from 'vue-cropperjs';
  export default {
    name: "cropper-imagem",
    components: {VueCropper},
    data: () => ({
      aspecto: '',
      aspectos: [
        {value: null, descricao: 'Livre'},
        {value: 16 / 9, descricao: '16:9'},
        {value: 9 / 16, descricao: '9:16'},
        {value: 4 / 3, descricao: '4:3'},
      ],
      /**/
      estiloCropper: {'width': '100%', 'height': '350px'},
      aspRatio: 16/9,
      rodar: 5,
      vcropperProgress: false,
      mantemExtensao: false,
      alertClose: false,
      isSvg:false,
      spinner: false,
      checkbox: '16/9',
      radioGroup: 1,
      estilo: null,
      informacao: null,
      mensagem: '',
      imgSrc: '',
      imgUrl: '',
      imgName: '',
      cropImg: '',
      tipoImg: '',
      titulo: '',
      display: 'none'
    }),

    props: {
      ratio: {
        default: ''
      },
      classe: {
        default: ''
      },
      imgURL: {
        default: ''
      },
      imagemId: {
        default: ''
      },
      flip: {
        default: false
      },
      _16_9: {
        default: false
      },
      _6_16: {
        default: false
      },
      _4_3: {
        default: false
      },
      livre: {
        default: false
      },
      // isSvg: {
      //   default: false
      // }
    },
    methods: {

      // alerta(mensagem, estilo) {
      //   var _this = this
      //   this.alertClose = true
      //   this.mensagem = mensagem
      //   this.estilo = estilo
      //   var itervalo = setTimeout(function () {
      //     _this.alertClose = false
      //     clearInterval(itervalo)
      //   }, 3000)
      // },
      // SalvarSvg() {
      //   this.spinner = true
      //   if (this.imgSrc === '' || this.imgSrc === null) {
      //     this.alerta('SELECIONE UMA IMAGEM', 'erro')
      //     this.spinner = false
      //   } else {
      //     this.$emit('cropperSvg', this.imgSrc, this.mantemExtensao)
      //   }
      //   this.$refs.cropper.destroy()
      //   this.$refs.cropper.clear()
      // },
      // limpar() {
      //   var imageCropper = document.getElementById('imageCropper')
      //   var divBack = document.getElementById('divBack')
      //   divBack.classList.remove('vcropperBackground2');
      //   divBack.classList.add('vcropperBackground');
      //   imageCropper.style.display = 'none'
      // },
      // Fechar() {
      //   this.$refs.cropper.destroy()
      //   this.$refs.cropper.clear()
      //   this.display = 'none'
      //   this.limpar()
      //   this.$emit('showCropper', false)
      // },
      // TrocarClasse() {
      //   var imageCropper = document.getElementById('imageCropper')
      //   var divBack = document.getElementById('divBack')
      //   var classes = divBack.className.split(' ')
      //
      //   var getIndex
      //   classes.splice(getIndex, 1)
      //
      //   let tela = window.screen.availWidth
      //
      //   if (tela <= 360) {
      //     this.estiloCropper = {'width': '100%', 'height': '200px'}
      //     getIndex = classes.indexOf('vcropperBackground2300')
      //     if (getIndex === -1) {
      //       classes.push('vcropperBackground2300')
      //       divBack.className = classes.join(' ')
      //     }
      //   } else {
      //     this.estiloCropper = {'width': '100%', 'height': '400px'}
      //     getIndex = classes.indexOf('vcropperBackground2')
      //     if (getIndex === -1) {
      //       classes.push('vcropperBackground2')
      //       divBack.className = classes.join(' ')
      //     }
      //   }
      //   imageCropper.style.display = 'block'
      // },
      // setImage(e, mantemExtensao) {
      //   this.mantemExtensao = mantemExtensao
      //   this.TrocarClasse()
      //   this.$refs.cropper.initCrop()
      //   // const file = e.target.files[0]
      //   const file = e.files[0]
      //   // const file = e.files
      //
      //   if (!file.type.includes('image/')) {
      //     this.alerta('SELECIONE UMA IMAGEM', 'erro')
      //     this.spinner = false
      //     return
      //   }
      //
      //   this.isSvg = file.type.includes('image/svg+xml')
      //
      //   if (typeof FileReader === 'function') {
      //     const reader = new FileReader()
      //     reader.onload = (event) => {
      //       this.imgSrc = event.target.result
      //       this.imgUrl = this.imgSrc
      //       this.tipoImg = file.type
      //       this.imgName = file.name
      //       this.$refs.cropper.replace(event.target.result)
      //     }
      //     reader.readAsDataURL(file)
      //   } else {
      //     this.alerta('SELECIONE UMA IMAGEM', 'erro')
      //     this.spinner = false
      //     this.vcropperProgress = false;
      //   }
      // },
      // cropImage() {
      //   this.spinner = true
      //   if (this.imgSrc === '' || this.imgSrc === null) {
      //     this.alerta('SELECIONE UMA IMAGEM', 'erro')
      //     this.spinner = false
      //   } else {
      //     this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL(this.tipoImg)
      //     if (this.tipoImg === '') {
      //       this.tipoImg = this.base64MimeType(this.cropImg)
      //       var extencao = this.tipoImg.replace('image/', '')
      //       this.imgName = 'IMG.' + extencao
      //     }
      //     let atualizou
      //     if (this.imgURL != null) {
      //       atualizou = true
      //     } else {
      //       atualizou = false
      //     }
      //     this.$emit('vcropper', this.cropImg, this.mantemExtensao)
      //   }
      //   this.$refs.cropper.destroy()
      //   this.$refs.cropper.clear()
      // },
      // base64MimeType(encoded) {
      //   var result = null
      //   if (typeof encoded !== 'string') {
      //     return result
      //   }
      //   var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
      //   if (mime && mime.length) {
      //     result = mime[1]
      //   }
      //   return result
      // },
      // setAspectioRatio(){
      //   if (this.checkbox === '3/4' ) {
      //     this.aspRatio = 3 / 4
      //
      //     this.$refs.cropper.setAspectRatio(this.aspRatio)
      //   } else if (this.checkbox === '4/3' || this._4_3) {
      //     this.aspRatio = 4 / 3
      //     this.checkbox = '4/3'
      //     this.$refs.cropper.setAspectRatio(this.aspRatio)
      //   } else if (this.checkbox === '16/9' || this._16_9) {
      //     this.aspRatio = 16 / 9
      //     this.checkbox = '16/9'
      //     this.$refs.cropper.setAspectRatio(this.aspRatio)
      //   } else if (this.checkbox === '12/16') {
      //     this.aspRatio = 12/16
      //     this.checkbox = '12/16'
      //     this.$refs.cropper.setAspectRatio(this.aspRatio)
      //   } else if (this.checkbox === 'flip') {
      //     this.aspRatio = 7.5 / 10
      //     this.$refs.cropper.setAspectRatio(this.aspRatio)
      //   } else if (this.checkbox === 'livre' || this.livre) {
      //     this.aspRatio = null
      //     this.checkbox = 'livre'
      //     this.$refs.cropper.setAspectRatio(this.aspRatio)
      //   } else {
      //     this.$refs.cropper.setAspectRatio(0)
      //   }
      // }
    },
    mounted () {
      // this.setAspectioRatio()
      // if (this.ratio !== '') {
      //   this.checkbox = this.ratio
      // } else {
      //   this.checkbox = 'livre'
      // }

      // if (this.ratio !== '') {
      //   this.$refs.cropper.setAspectRatio(this.ratio)
      // }
    },
    watch: {
      // 'checkbox': function () {
      //   this.setAspectioRatio()
      // }
    },
  }
</script>

<style scoped>
  @import '../assets/css/CropperCss.css';
  input[type=file] {
    visibility: hidden;
  }

  .icon_inp {
    cursor: pointer;
    padding: 5%;
  }


  .vcropperBackground2 {
    background: url('VCropper/vcropperBackground.png') repeat-x;
    width: 100%;
    height: 400px;
    border: 1px gray solid;
  }

  .vcropperBackground2300 {
    background: url('VCropper/vcropperBackground.png') repeat-x;
    width: 100%;
    height: 200px;
    border: 1px gray solid;
  }

  .vcropperBackground {
    width: 100%;
    height: 350px;
    /*background-image: url('../../../static/img/vcropperBackground.png');*/
    /*background-repeat: repeat-x;*/
    background: transparent;
    border: 1px gray solid;
  }

  .vcropperBackground300 {
    width: 100%;
    height: 200px;
    background: transparent;
    border: 1px gray solid;
  }

  .listaImagem {
    width: 200px;
    height: 150px;
    border: 1px solid gray;
  }
</style>

