<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
	 <div>
     <v-menu open-on-hover location="end" transition="scale-transition">
       <template v-slot:activator="{ props  }">
         <v-avatar v-bind:="props " size="35"  class="text-left" >
           <v-img :src="selecionaImagem(item, '50')" alt="avatar"></v-img>
         </v-avatar>
       </template>
       <v-card style="width: 300px">
         <v-card-title>
           <v-img :src="selecionaImagem(item, '400')" alt="avatar" class="imgZoom" contain></v-img>
         </v-card-title>
       </v-card>
     </v-menu>
	 </div>
</template>

<script>
  /* eslint-disable */
  import miscImageCropper from '../helpers/img-cropper'
  export default {
    name: "MenuImagem",
    mixins: [miscImageCropper],
    props: ['item'],
  }
</script>
