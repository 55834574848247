/* eslint-disable */
import {lista, processar, pegaLista, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";

import {ProfissionalSCH, getProfissionalSCH} from "../../search/ProfissionalSCH"
import {EventBus} from '../../helpers/event-bus'
import {Profissional} from '../../models/profissional'

const url = '/profissional'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro(),
  profissional: new Profissional(),
}

const getters = {
  listaAllProfissionals: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },

  listaProfissionals: (state) => {
    return state.all
  },

  pegaProfissional: (state) => {
    return state.profissional
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListProfissional({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
  },

  searchItem({commit}, search) {
    state.filtro.filters.addSearch(['titulo'] ,
      "%"+search+"%",
      'ilike'
    )
  },
  async getItem({commit}, id) {
     var filtro = getBaseSCH('profissional_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
      commit('SET_ProfissionalS', list.data[0])
     EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', ProfissionalSCH(perPage))
  },
  setProfissional({commit}, profissional) {
     commit('SET_PROFISSIONAL', profissional)
  },
  async setAllProfissionals({commit}, {onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_PROFISSIONALS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
  async processarProfissional({commit}, {profissional, acao,onSucesso,onErro}) {
    try {
      console.log(profissional)
      let res = await processar(url + (acao === 'gravar' ? '/commit' : ''), profissional, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  }
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_PROFISSIONALS(state, profissionals) {
      state.all = profissionals
  },
  SET_PROFISSIONAL(state, profissional) {
      state.profissional = profissional
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
