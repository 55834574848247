<template>
			<v-row>
						<v-col v-for="(n, index) in imagens" :key="index" cols="12" :sm="multiplo ? 6 : 12" :md="multiplo ? 3 : 12"
													:lg="multiplo ? 2 : 12" v-if="imagens.length > 0">
									<v-hover v-slot="{ isHovering, props }">
												<v-card v-bind="props">
															<!--<v-img :src="selecionaImagem(item.urls_imagem, tamanho, u.anexo)" height="140px" cover></v-img>-->
															<v-img :src="n.src ? n.src : n.url" height="140px" cover></v-img>
															<v-overlay :model-value="isHovering" contained scrim="#036358" class="align-center justify-center">
																		<div style="width: 100%; ">
																					<v-row>
																								<v-col cols="12">
																											<v-btn small color="red darken-1" @click="removeImagemObjeto(n.anexo_id)">
																														<v-icon small>mdi-clear</v-icon>
																														Remover
																											</v-btn>
																								</v-col>
																					</v-row>
																		</div>
															</v-overlay>
												</v-card>
									</v-hover>
						</v-col>

						<v-col cols="12" :sm="multiplo ? 6 : 12" :md="multiplo ? 3 : 12" :lg="multiplo ? 2 : 12"
													v-if="isMultiplo()">
									<v-card ripple style="text-align: center; padding: 5%; height: 140px">
												<input type="file" name="addFotoGaleria[]" class="inputfile inputfile-7" id="addFotoGaleria"
																			multiple accept=".png, .jpg, .jpeg" ref="fileInput" @change="carregarImagens"
																			data-multiple-caption="{count} files selected" :disabled="disabled"/>
												<label for="addFotoGaleria">
															<v-icon style="font-size: 5rem" class="icon_inp" :color="disabled ? '#ccc' : 'black'">mdi-camera-plus</v-icon>
															<p :style="'color:'+ (disabled ? '#ccc' : 'black')">Adicionar</p>
												</label>
									</v-card>

									<v-dialog v-model="dialog" max-width="40%">
												<v-card>
															<v-card-title style="background: #E2A86E; color: #2c1710">
																		Selecione e corte a imagem
															</v-card-title>
															<v-card-text>
																		<div v-if="src">
																					<v-row justify="end">
																								<v-col cols="12">
																											<div class="vuecropper">
																														<vue-cropper ref='cropper' :guides="true" :view-mode="1" drag-mode="crop"
																																											:auto-crop-area="1" :background="true" :rotatable="true" :auto-crop="true"
																																											:img="imgUrl" :src="imgSrc" :aspectRatio="aspRatio" id="imageCropper"
																																											:img-style="estiloCropper">
																														</vue-cropper>
																											</div>
																								</v-col>
																					</v-row>
																		</div>
															</v-card-text>
															<v-card-actions>
																		<v-autocomplete v-model="aspecto" :items="aspectos" item-title="descricao" item-value="value"
																																		name="asp" variant="outlined" density="compact" id="ratio"
																																		label="Relação de aspecto:" persistent-hint return-object></v-autocomplete>
																		<v-spacer></v-spacer>
																		<v-btn color="primary" text @click="cancel">Cancelar</v-btn>
																		<v-btn color="primary" text @click="save">Cortar</v-btn>
															</v-card-actions>
												</v-card>

									</v-dialog>
						</v-col>
			</v-row>
</template>

<script>
   import VueCropper from 'vue-cropperjs';
   import 'cropperjs/dist/cropper.css';
   import {Anexo} from "../../models/anexo";

   import miscImageCropper from '../../helpers/img-cropper'
   import {EventBus} from '../../helpers/event-bus'
   import {Imagem} from "../../models/banner/imagem";

   export default {
      // mixins: [miscImageCropper],

      components: {VueCropper},
      props: {
         dragMode: {type: String, default: 'move'},
         autoCropArea: {type: Number, default: 0.8,},
         item: {default: []},
         multiplo: {default: false},
         row: {default: false},
         disabled: {default: false},
         pre3: {default: false},
      },
      data() {
         return {
            overlay: false,
            imagens: new Imagem(),
            files: [],
            cropMode: 'crop',
            aspRatio: null,
            display: 'none',
            imgSrc: '',
            imgUrl: '',
            estiloCropper: {'width': '100%', 'height': '350px'},
            src: null,
            dialog: false,
            aspecto: '',
            aspectos: [
               {value: null, descricao: 'Livre'},
               {value: 16 / 9, descricao: '16:9'},
               {value: 9 / 16, descricao: '9:16'},
               {value: 4 / 3, descricao: '4:3'},
            ],
         };
      },
      methods: {
         isMultiplo() {
            return this.multiplo ? true : this.imagens.length === 0
         },
         // getColuna(){
         //    console.log('getColuna: ',this.$vuetify.display);
         //    console.log('getColuna: ',this.$vuetify.display.lgAndDown);
         //    console.log('getColuna: ',this.$vuetify.display.mdAndDown);
         //    console.log('getColuna: ',this.$vuetify.display.smAndDown);
         // },
         removeImagemObjeto(id) {
            let tempFiltro = this.imagens.filter((valor) => {
               return valor.anexo_id !== id
            })
            this.imagens = tempFiltro
            EventBus.$emit('SET_IMAGENS', this.imagens)
         },
         cancel() {
            this.dialog = false;
            this.$refs.cropper.cropper.destroy()
            this.$refs.cropper.cropper.clear()
         },
         save() {
            const croppedImage = this.$refs.cropper.cropper.getCroppedCanvas().toDataURL();
            if (croppedImage === '' || croppedImage === null) {
               alert('SELECIONE UMA IMAGEM')
            } else {
               console.log('save');
               let _capa = this.imagens.length <= 0
               this.imagens.push(new Anexo('', croppedImage, _capa,true))
               EventBus.$emit('SET_IMAGENS', this.imagens)

               this.dialog = false;
               this.$refs.cropper.cropper.destroy()
               this.$refs.cropper.cropper.clear()

               // sessionStorage.setItem('imagens', JSON.stringify(this.imagens))
            }
         },

         openFilePicker() {
            this.$refs.fileInput.click();
         },
         onFileChange(event) {
            const file = event.target.files[0];
            if (!file) return;

            const reader = new FileReader();
            reader.onload = (e) => {
               this.src = e.target.result;
               this.dialog = true
               this.$nextTick(() => {
                  this.$refs.cropper.cropper.replace(this.src);
               });
            };
            reader.readAsDataURL(file);
         },
         carregarImagens(event) {
            let _this = this;

            const file = event.target.files[0];
            if (!file) return;

            this.files = event.target.files
            var quantImagens = this.files.length;
            if (quantImagens === 1) {
               var fileSize = (Math.round(this.files[0].size * 100 / (1024 * 1024)) / 100).toString();
               if (fileSize > 3) {
                  alert('Tamanho da imagem excedido:' + fileSize + 'Mb \nO tamanho máximo permitido é de 3 Mb')
               } else {
                  _this.onFileChange(event)
               }
            } else {
               _this.carregarVariasImagens(this)
            }
         },
         carregarVariasImagens(input) {
            let _this = this;
            let maximoTamanho = 3 * 1024 * 1024; // maximo 3 MB
            if (input.files) {
               let quantImagens = input.files.length;
               for (let i = 0; i < quantImagens; i++) {
                  if (input.files[i].size <= maximoTamanho) {
                     let reader = new FileReader();
                     reader.onload = function (event) {
                        let img = document.createElement('img');
                        img.src = event.target.result;
                        let _capa = _this.imagens.length <= 0;
                        _this.imagens.push(new Anexo('', img.src, _capa,true))

                     };
                     reader.readAsDataURL(input.files[i])
                  } else {
                     alert('Arquivo muito grande. Máximo 3MB.')
                  }
               }
               EventBus.$emit('SET_IMAGENS', _this.imagens)
            }
         },

         selecionaImagem(urls, tamanho, anexoId) {
            let url
            let urlsPesquisa
            if (anexoId !== undefined) {
               let tempAnexo = urls.find(x => x.anexo === anexoId)
               if (tempAnexo !== null && tempAnexo !== undefined) {
                  urlsPesquisa = tempAnexo.urls
               }
            } else {
               if (urls !== undefined && urls.length > 0) {
                  let achou = urls.find(c => c.capa === 1)
                  if (achou !== undefined) {
                     urlsPesquisa = achou.urls
                  } else {
                     urlsPesquisa = urls[0].urls
                  }
               }
            }
            if (urlsPesquisa !== undefined && urlsPesquisa.length > 0) {
               url = urlsPesquisa.find(x => x.tamanho === tamanho)

               if (url === undefined) {
                  url = urlsPesquisa.find(x => x.tamanho === 'original')
               }
            } else {
               url = {
                  url: empty
               }
            }
            return url.url
         },


         // preencheObjeto2({objeto, tamanho, onSucesso}) {
         preencheObjeto2(objeto, tamanho) {
            // console.log('preencheObjeto2');
            this.imagens = [];
            var _this = this
            if (objeto.urls_imagem !== undefined) {
               objeto.urls_imagem.forEach(u => {
                  let anexo = new Anexo(this.selecionaImagem(objeto.urls_imagem, tamanho, u.anexo), '', u.capa, true);
                  // console.log(anexo);
                  anexo.anexo_id = u.anexo;
                  if (anexo.url !== _this.empty) {
                     _this.imagens.push(anexo)
                  }
               })
            }
            this.$emit('set_img', _this.imagens)
            // EventBus.$emit('SET_IMAGENS', _this.imagens)
         },
									preencheObjeto3(objeto, tamanho) {
            // console.log('preencheObjeto3');
            // console.log(objeto);
            this.imagens = [];
            var _this = this
            if (objeto.urls_imagem !== undefined) {
               objeto.urls_imagem.forEach(u => {
                  let anexo = new Anexo(this.selecionaImagem(objeto.urls_imagem, tamanho, u.anexo), '', u.capa, true);
                  // console.log(anexo);
                  anexo.anexo_id = u.anexo;
                  if (anexo.url !== _this.empty) {
                     _this.imagens.push(anexo)
                  }
               })
            }
            // this.$emit('set_img', _this.imagens)
            EventBus.$emit('SET_IMAGENS', _this.imagens)
         },
      },
      watch: {
         aspecto: function () {
            if (this.aspecto !== null && this.aspecto !== '' && this.aspecto !== undefined) {
               this.aspectRatio = this.aspecto.value
               this.$refs.cropper.cropper.setAspectRatio(this.aspectRatio)
            } else {
               this.aspectRatio = null
            }
         }
      },
      mounted() {
									if (this.pre3) {
            this.preencheObjeto3(this.item, '400')
									}else {
            this.preencheObjeto2(this.item, '400')
									}
      },
   };
</script>
<style scoped>
			@import "CropperCss.css";

			input[type=file] {
						visibility: hidden;
			}

			.icon_inp {
						cursor: pointer;
						padding: 5%;
			}
</style>
