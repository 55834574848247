import router from '../router'

export default {
  data: () => ({
    dialog_loading: null,
    show_list: false,
    listaItens: [],
    info_alerta: '',
    cor_alerta: 'primary',
    show_alerta: false,
    precisao: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false,
      allowBlank: false,
      min: Number.MIN_SAFE_INTEGER,
      max: Number.MAX_SAFE_INTEGER
    },
    customToolbar: [
      [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
      ['bold', 'italic'], // 'underline', 'strike'
      [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],//{ 'list': 'check' }
      [{ 'color': [] }], //{ 'background': [] }
      ['link', 'formula'],   // 'image'
      ['clean'],
    ]
  }),
  methods: {
    inverterData(newData) {
      var xvals = '';
      if (this.validarCampo(newData)) {
        xvals = newData.split('-');
        return xvals[2] + '/' + xvals[1] + '/' + xvals[0]
      } else {
        return xvals
      }
    },
    zeroEsquerda(value, totalWidth, paddingChar) {
      var length = totalWidth - value.toString().length + 1
      return Array(length).join(paddingChar || '0') + value
    },
    removerMascara (str) {
      if (this.validarCampo(str)) {
        return str.replace(/[^0-9]+/g, '')
      } else {
        return ''
      }
    },
    getDataAtual() {
      var d = this.zeroEsquerda(new Date().getDate(), 2)
      var m = this.zeroEsquerda(new Date().getMonth() + 1, 2)
      var a = new Date().getFullYear()
      return a + '-' + m + '-' + d
    },

    getValueByKey (item,key) {
      if (item[key] === undefined) {
        return item
      } else {
        return item[key]
      }
    },
    logs(obj){
      if (this.validarCampo(obj)) {
        return JSON.parse(JSON.stringify(obj))
      } else {
        return obj
      }
    },
    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    itens(item) {
      this.listaItens = item
    },
    closeDialog(dialog) {
      dialog.close()
    },
    onCancel() {
      this.value = 'Não'
    },
    open_alerta(text, cor) {
      this.info_alerta = text
      this.cor_alerta = cor
      this.show_alerta = true
      var _this = this
      var intervalo = setInterval(function () {
        _this.show_alerta= false
        clearInterval(intervalo)
      }, 6000)
    },
    fechar_alerta() {
      this.show_alerta = false
    },
    removerMasK(str) {
      return this.validarCampo(str) ? str.replace(/[^0-9a-zA-Z]+/g, '') : ''
    },
    cortarString(str, tam) {
      if (this.validarCampo(str)) {
        if (str.length > tam) {
          str = str.slice(0, tam) + '...'
        }
      }
      return str
    },
    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    retira_acentos(str) {
      var com_acento = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ!'
      var sem_acento = 'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr '
      var novastr = ''
      for (var i = 0; i < str.length; i++) {
        var troca = false
        for (var a = 0; a < com_acento.length; a++) {
          if (str.substr(i, 1) === com_acento.substr(a, 1)) {
            novastr += sem_acento.substr(a, 1)
            troca = true
            break
          }
        }
        if (troca === false) {
          novastr += str.substr(i, 1)
        }
      }
      return novastr
    },

    redimensionando(id) {
      var teste_div = document.getElementById(id)
      var eleStyle = window.getComputedStyle(teste_div)
      var height = 760
      var hg = parseInt(eleStyle.width.replace('px', '')) - 430
      if (parseInt(eleStyle.width.replace('px', '')) <= 1100) {
        height = hg > 300 ? hg : 300
      } else {
        height = hg > 300 ? (hg > 760 ? 760 : hg) : 300
      }
      return height
    },
    IrParaUrl(path, target = '_blank') {
      var a = document.createElement('a')
      a.href = path
      a.target = target
      a.click()
    },
    scrollToSection(id, block = 'start') {
      var elmnt = document.getElementById(id)
      if (this.validarCampo(elmnt)) {
        elmnt.scrollIntoView({
          behavior: 'smooth',
          block: block
        })
      }
    },
    irPara(path, block = 'start') {
      var url_atual = this.retira_acentos(decodeURIComponent(window.location.pathname))
      var cortar = url_atual.replace('/', '')
      if (this.validarCampo(cortar)) {
        router.push({path: path}).catch(err => {
        })
        var _this = this
        var inter = setInterval(function() {
          _this.scrollToSection(path, block)
          clearInterval(inter)
        }, 100)
      } else {
        router.push({path: path}).catch(err => {
        })
        this.scrollToSection(path, block)
      }
    },
    irParaRota(path) {
      this.scrollToSection('app')
      router.push({path: path}).catch(err => {
      })
    },
    scrollTop() {
      var elmnt = document.getElementById('inspire')
      elmnt.scrollIntoView({behavior: 'smooth'})
    },
  },
}
