/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Profissional {
  constructor () {
    this.profissional_id = uuidV4()
    this.nome = ''
    this.crm = ''
    this.descricao = ''
    this.especialidade = ''
    this.imagens = []
  }
}
