<template>
	 <v-app id="app">
			<v-main class="backmain">
				 <router-view/>
			</v-main>
	 </v-app>
</template>
<script>
		export default {
    name: 'App',
    components: {
      },
    created() {
    },
  }
</script>
<style scoped>
			.backmain{
						/*margin: 0 auto;*/
						/*width: 100%;*/
						/*position: relative;*/
			   background-image: url('./assets/img/fundo2.png') !important;
			   /*background-image: url('./assets/img/fundo.png') !important;*/
						background-repeat: no-repeat;
						/*background-attachment: fixed;*/
						background-size: cover !important;
			}
</style>
