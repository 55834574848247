/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class DadosClinica {
  constructor () {
    this.dados_clinica_id = uuidV4()
    this.nome_clinica = ''
    this.fone1 = ''
    this.fone2 = ''
    this.email = ''
    this.logrado = ''
    this.numero = ''
    this.complemento = ''
    this.bairro = ''
    this.cidade = ''
    this.uf = ''
    this.cep = ''
    this.facebook = ''
    this.instagram = ''
    this.whatsapp = ''
    this.latitude = ''
    this.longitude = ''
    this.imagens = []
  }
}
