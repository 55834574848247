/* eslint-disable */
import {Anexo} from '../models/anexo'
import empty from '../assets/img/empty.jpg'
import miscMixin from './misc'
import CropperImagem from "../components/CropperImagem";

export default {
  mixins: [miscMixin],
  data() {
    return {
      showCropper: false,
      imagens: [],
      classe: 'vcropperBackground',
      IMG: null
    }
  },
  components: {
    CropperImagem
    // CropperImagem: () => import('../components/CropperImagem.vue')
  },
  methods: {
    PegaImagem(imgPath, mantemExtensao) {
      var id = this.imagens.length + 1
      let _capa = this.imagens.length <= 0 // quando estiver adicionando a primeira imagem a mesma será capa
      this.imagens.push(new Anexo('', imgPath, _capa, mantemExtensao))
      this.$refs.cropperIMG.spinner = false
      this.showCropper = false
    },

    carregarImagemIndividual(input, mantemExtensao) {
      var _this = this
      if (input.files && input.files[0]) {
        this.IMG = event.target.result
        var reader = new FileReader();
        reader.onload = function (evt) {
          var img = new Image();
          img.src = evt.target.result;
          _this.$refs.cropperIMG.setImage(input, mantemExtensao)
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    RemoverIMG(img) {
      let _this = this
      this.$dialog.confirm('Deseja excluir esse registro?')
        .then(function (dialog) {
          let pos = _this.imagens.indexOf(img)
          _this.imagens.splice(pos, 1)
          _this.closeDialog(dialog)
        })
    },

    selecionaImagem(urls, tamanho, anexoId) {
      let url
      let urlsPesquisa
      if (anexoId !== undefined) {
        let tempAnexo = urls.find(x => x.anexo === anexoId)
        if (tempAnexo !== null && tempAnexo !== undefined) {
          urlsPesquisa = tempAnexo.urls
        }
      } else {
        if (urls !== undefined && urls.length > 0) {
          let achou = urls.find(c => c.capa === 1)
          if (achou !== undefined) {
            urlsPesquisa = achou.urls
          } else {
            urlsPesquisa = urls[0].urls
          }
        }
      }
      if (urlsPesquisa !== undefined && urlsPesquisa.length > 0) {
        url = urlsPesquisa.find(x => x.tamanho === tamanho)

        if (url === undefined) {
          url = urlsPesquisa.find(x => x.tamanho === 'original')
        }
      } else {
        url = {
          url: empty
        }
      }
      return url.url
    },

    preencheObjeto(objeto, tamanho) {
      this.imagens = []
      if (objeto.urls_imagem !== undefined) {
        objeto.urls_imagem.forEach(u => {
          let anexo = new Anexo(this.selecionaImagem(objeto.urls_imagem, tamanho, u.anexo), '', u.capa, true)
          anexo.anexo_id = u.anexo
          // console.log(anexo);
          if (anexo.url !== empty) {
            this.imagens.push(anexo)
          }
        })
      }

    },

    removeImagemObjeto(id) {
      let tempFiltro = this.imagens.filter((valor) => {
        return valor.anexo_id !== id
      })
      this.imagens = tempFiltro
    },

    defineCapaImagem(id) {
      this.imagens.forEach(i => {
        i.capa = (i.anexo_id === id)
      })
    },

    carregarVariasImagens(input, mantemExtensao) {
      let _this = this
      let maximoTamanho = 2* 1024 * 1024 // maximo 3 MB
      if (input.files) {
        let quantImagens = input.files.length
        for (let i = 0; i < quantImagens; i++) {
          if (input.files[i].size <= maximoTamanho) {
            let reader = new FileReader()
            reader.onload = function (event) {
              let img = document.createElement('img')
              img.src = event.target.result
              let _capa = _this.imagens.length <= 0 // quando estiver adicionando a primeira imagem a mesma será capa
              _this.imagens.push(new Anexo('', img.src, _capa, mantemExtensao))
            }
            reader.readAsDataURL(input.files[i])
          } else {
            // colocar um alert melhor aqui depois
            alert('Arquivo muito grande. Máximo 2MB.')
          }
        }
      }
    },

    fecharModalCropper(fechar) {
      this.showCropper = fechar
      this.IMG = null
    },

    erroAdd(erros) {
      alert.log(erros)
    },

    carregarImagens(mantemExtensao) {
      let _this = this
      let $ = require('jquery')
      $('#addFotoGaleria').on('change', function () {
        var quantImagens = this.files.length;
        if (quantImagens === 1) {
          var fileSize = (Math.round(this.files[0].size * 100 / (1024 * 1024)) / 100).toString()
          if (fileSize > 3) {
            alert('Tamanho da imagem excedido:' + fileSize + 'Mb \nO tamanho máximo permitido é de 3 Mb')
          } else {
            _this.showCropper = true
            _this.carregarImagemIndividual(this, mantemExtensao)
          }
        } else {
          _this.carregarVariasImagens(this, mantemExtensao)
        }
        $('#addFotoGaleria').off('change')
      })
    }
  }
}
