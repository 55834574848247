/* eslint-disable */
import {lista, processar, pegaLista,pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";

import {DadosClinicaSCH} from "../../search/DadosClinicaSCH"
import {EventBus} from '../../helpers/event-bus'
import {DadosClinica} from "../../models/dados_clinica";

const url = '/dados_clinica'

const state = {
  dados_clinica: new DadosClinica(),
  filtro: new Filtro(),
}

const getters = {
  pegaDadosClinica: (state) => {
    return state.dados_clinica
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  // async setAllListDadosClinica({ commit }) {
  //   const list = (await lista(url + '/all')).data
  //   commit('SET_ALL_LIST', list)
  //   EventBus.$emit('DADOS_CLINICA_ALL_CONCLUIDO', true)
  // },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', DadosClinicaSCH(perPage))
  },

  setDadosClinica({commit}, dados_clinica) {
    commit('SET_DADOS_CLINICA', dados_clinica)
  },

  async setAllDadosClinica({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_DADOS_CLINICA', list.data[0])
      } else {
        commit('SET_DADOS_CLINICA', new DadosClinica())
      }

      onSucesso()
    } catch (error) {
      onErro()
    }

  },

  async processarDadosClinica({commit}, {dados_clinica, acao,onSucesso, onErro}) {
    try {
      console.log(dados_clinica);
      let res = await processar(url+'/commit', dados_clinica, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  }
}

const mutations = {
  SET_DADOS_CLINICA(state, dados_clinica) {
      state.dados_clinica = dados_clinica
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
