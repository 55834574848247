import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // base: process.env.BASE_URL,
  routes: [
     {path: '/', name: 'Login',component: () => import(/* webpackChunkName: "Login" */ './views/Login.vue')},
    {
      path: '/dash',
      name: 'Dash',
      component: () => import(/* webpackChunkName: "Dash" */ './views/Dash.vue'),
      children: [
        {path: '/home', name: 'Home',component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue')},
        {path: '/tratamento', name: 'Tratamentos',component: () => import(/* webpackChunkName: "Tratamento" */ './views/Cadastro/tratamento/Tratamento.vue')},
        {path: '/tratamento/cadastrar', name: 'TratamentoCadastro',component: () => import(/* webpackChunkName: "TratamentosCadastro" */ './views/Cadastro/tratamento/TratamentoCadastrar.vue')},
        {path: '/blog', name: 'Blog',component: () => import(/* webpackChunkName: "Blog" */ './views/Cadastro/blog/Blog.vue')},
        {path: '/blog/cadastrar', name: 'BlogCadastro',component: () => import(/* webpackChunkName: "BlogCadastro" */ './views/Cadastro/blog/BlogCadastrar.vue')},
        {path: '/profissional', name: 'Profissional',component: () => import(/* webpackChunkName: "Profissional" */ './views/Cadastro/profissional/Profissional.vue')},
        {path: '/profissional/cadastrar', name: 'ProfissionalCadastro',component: () => import(/* webpackChunkName: "ProfissionalCadastro" */ './views/Cadastro/profissional/ProfissionalCadastrar.vue')},
        {path: '/local_atendimento', name: 'Local de Atendimento',component: () => import(/* webpackChunkName: "LocalAtendimento" */ './views/Cadastro/LocalAtendimento/LocalAtendimento.vue')},
        {path: '/usuario', name: 'Usuario',component: () => import(/* webpackChunkName: "Usuario" */ './views/Cadastro/usuarios/Usuario.vue')},
        {path: '/dados_clinica', name: 'Dados Clinica',component: () => import(/* webpackChunkName: "dados_clinica" */ './views/Cadastro/dados_clinica/DadosClinica.vue')},
        {path: '/convenio', name: 'Convenio',component: () => import(/* webpackChunkName: "Convenio" */ './views/Cadastro/Convenios/Convenio.vue')},
    //
      ]
    },
    {path: '/banner', name: 'Banner2',component: () => import(/* webpackChunkName: "Banner" */ './views/Cadastro/Banner2/Banner2.vue')},

    { path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import(/* webpackChunkName: "404" */ './views/NotFound.vue')
    },

  ]
})

export default router
