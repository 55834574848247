<template>
	 <div>
			<v-card-title>
				 <div :class="classe" style="width: 50%; text-align: left">
						<h3 data-aos="fade-down" class="tommy_bold">{{titulo}}</h3>
						<div data-aos="fade-right" class="progressAnimated" :style="'width:' +width"></div>
				 </div>
			</v-card-title>
	 </div>
</template>

<script async>
  export default {
    name: 'titulo',
    props: {
      titulo: {default: ''},
      classe: {default: 'txt_azul'},
      width: {default: '50%'},
    },
  }
</script>
