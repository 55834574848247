<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
			<div class="table_iterator">
						<v-dialog v-model="loading" width="300">
									<v-card :color="setLoadingColor('green')" dark>
												<v-card-text>
															Carregando...
															<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
												</v-card-text>
									</v-card>
						</v-dialog>

						<v-table fixed-header sortable density="compact">
									{{listaCabecalho}}
									<thead>
									<tr>
												<th :class="'text-'+item.align" v-for="(item, i) in lista_cab"
																role="columnheader" scope="col"
																:aria-sort="item.aria_sort"
																:id="'th_'+i"
																@click="ordenando(item,index)"
												>
															<!--<v-btn variant="text" block :style="'padding-left: 0;padding-right: 0; justify-content:'  +item.align">-->
															<div :style="'padding-left: 0;padding-right: 0; justify-content:'  +item.align + '; cursor:' + (item.sortable ? 'pointer' : 'inherit')">
																		<span style="text-transform: uppercase; color:#515151; text-align: right">{{item.text}}</span>
																		<v-icon v-if="isSortable(item)">{{item.icone}}</v-icon>
															</div>
												</th>
									</tr>
									</thead>
									<tbody>
									<tr v-for="item in itens" :key="item.name">
												<td v-for="(n, index) in cabecalho" :key="'index'+index">
															<slot v-if="n.value !=='actions'" :name="n.slot" v-bind:item="item"></slot>
															<slot v-else name="acao" v-bind:item="item"></slot>
												</td>
									</tr>
									<tr v-if="itens.length <=0">
												<td colspan="100%">
															<v-alert
																	color="deep-orange"
																	icon="mdi-alert"
																	title="Nada há exibir no momento"
																	variant="outlined"
															>
															</v-alert>
												</td>

									</tr>
									</tbody>
									<tfoot>
									<tr>
												<td colspan="100%">
															<v-container >
																		<div>
																					<v-row justify="space-between" style="align-items: flex-end;padding: 5px">
																								<v-col cols="12" sm="3" md="2" lg="1">
																											<v-text-field v-model="select" type="number" label=""  persistent-hint
																																									min="1"  density="compact"  single-line hide-details></v-text-field>
																											<span style="color: gray; font-size: 10px">Itens por página</span>
																								</v-col>
																								<v-col cols="12" sm="6" md="8" lg="9">
																											<v-pagination v-model="page" :length="totalPaginas" :total-visible="7" density="compact"
																																									rounded="circle" active-color="green" variant="flat"/>
																								</v-col>
																								<v-col cols="12" sm="3" md="2" lg="2">
																											<v-btn v-if="showAddButton" @click="goToAdd" color="blue-darken-1"
																																		:disabled="disabledAddButton" prepend-icon="mdi-plus">
																														NOVO
																											</v-btn>
																								</v-col>
																								<slot name="slot_footer"></slot>
																					</v-row>

																		</div>
															</v-container>
												</td>
									</tr>
									</tfoot>
						</v-table>
						<!--<v-data-iterator :items="itens" :items-per-page.sync="itensPerPage" :page.sync="page" :search="search"-->
						<!--:sort-by="sortBy.toLowerCase()" :sort-desc="sortDesc" hide-default-footer :dense="dense">-->
						<!--<template v-slot:default="props">-->
						<!--{{lista(props.items)}}-->
						<!--<v-data-table fixed-header :headers="cabecalho" :items="props.items" :page.sync="page" :items-per-page="select"-->
						<!--hide-default-footer :search="search" class="elevation-0" @page-count="pageCount = $event" :id="id"-->
						<!--:expanded.sync="expanded" :hide-default-header="hasheader" @contextmenu:row="isshowContextMenu"-->
						<!--:item-key="''+chave" :dense="dense" must-sort>-->

						<!--<template v-slot:header v-if="hasheader">-->
						<!--<thead class="v-data-table-header">-->
						<!--<tr class="v_tr">-->
						<!--<th role="columnheader" scope="col" :aria-sort="item.aria_sort" :class="item.classe"-->
						<!--@click="ordenando(item,index)" :id="'th_'+index" v-for="(item, index) in listaCabecalho"-->
						<!--:key="'cli'+index" :style="'text-align:'+ item.align">-->
						<!--<span v-if="item.text==='Opções'" style="padding-right: 5%; text-transform: uppercase">{{ item.text }}</span>-->
						<!--<span v-else style="text-transform: uppercase">{{ item.text }}</span>-->
						<!--<i v-if="isSortable(item)" aria-hidden="true"-->
						<!--:class="'v-icon notranslate v-data-table-header__icon mdi '+item.icone+' '" style="font-size: 18px;">-->
						<!--</i>-->
						<!--</th>-->
						<!--</tr>-->
						<!--</thead>-->
						<!--</template>-->

						<!--&lt;!&ndash;<template v-else v-slot:[`header.`+[n.value]]="{ header }" v-for="(n, index) in cabecalho" :id="index">&ndash;&gt;-->
						<!--&lt;!&ndash;{{ header.text.toUpperCase() }}&ndash;&gt;-->
						<!--&lt;!&ndash;</template>&ndash;&gt;-->

						<!--&lt;!&ndash;<template v-slot:[`item.`+[n.slot]]="{ item }" v-for="(n, index) in cabecalho" :id="index" >&ndash;&gt;-->
						<!--&lt;!&ndash;<slot :name="n.slot" v-bind:item="item"></slot>&ndash;&gt;-->
						<!--&lt;!&ndash;</template>&ndash;&gt;-->

						<!--<template v-slot:[`item.actions`]="{item }">-->
						<!--<div class="acao_tabela">-->
						<!--<slot name="acao" v-bind:item="item"></slot>-->
						<!--</div>-->
						<!--</template>-->

						<!--<template v-slot:[`item.expand`]="{item }">-->
						<!--<v-btn icon color="primary" @click="expandir(item, item[chave])">-->
						<!--<v-icon class="expand">expand_more</v-icon>-->
						<!--</v-btn>-->
						<!--</template>-->

						<!--<template v-slot:expanded-item="{headers,item }" id="expandido">-->
						<!--<td :colspan="headers.length" style="background: #fff;" class="expand_more">-->
						<!--<slot name="expandido" v-bind:item="item"></slot>-->
						<!--</td>-->
						<!--</template>-->
						<!--</v-data-table>-->

						<!--</template>-->

						<!--<template v-slot:no-data>-->
						<!--<v-alert border="left" colored-border color="deep-orange accent-4" elevation="2" v-if="hideFooter">-->
						<!--<h2 style="color: gray">Nada há exibir no momento</h2>-->
						<!--</v-alert>-->
						<!--<div style="text-align: center;padding: 85px" v-else>-->
						<!--<span style="font-weight: normal">Nada há exibir no momento</span>-->
						<!--</div>-->
						<!--</template>-->

						<!--<template v-slot:footer >-->

						<!--<v-container max-width="300" v-if="hideFooter">-->
						<!--<div>-->
						<!--<v-row style="padding: 12px">-->
						<!--<v-divider style="margin: 0"></v-divider>-->
						<!--</v-row>-->
						<!--<v-row dense style="align-items: flex-end;padding: 5px">-->
						<!--<div>-->
						<!--<v-text-field :value="select" type="number" label="" hint="Itens por página" persistent-hint min="1"-->
						<!--@input="select = parseInt($event, 10)"></v-text-field>-->
						<!--</div>-->
						<!--<v-spacer></v-spacer>-->
						<!--<v-pagination v-model="page" :length="totalPaginas" :total-visible="7" :color="setLoadingColor('red')"/>-->
						<!--<v-spacer></v-spacer>-->
						<!--<v-btn v-if="showAddButton" tile color="primary" @click="goToAdd" :disabled="disabledAddButton">-->
						<!--<v-icon>add</v-icon>-->
						<!--NOVO-->
						<!--</v-btn>-->
						<!--<slot name="slot_footer"></slot>-->
						<!--</v-row>-->
						<!--</div>-->
						<!--</v-container>-->
						<!--<v-row wrap v-else>-->
						<!--<v-col cols="12" style="padding: 0px">-->
						<!--<v-container max-width="300" style="padding: 10px 0px 0px 0px">-->
						<!--<v-pagination v-if="pagSiple" class="teste_page" v-model="page" :length="pageCount" :total-visible="7"></v-pagination>-->
						<!--<v-pagination v-else class="teste_page" v-model="page" :length="totalPaginas" :total-visible="7"></v-pagination>-->
						<!--</v-container>-->
						<!--</v-col>-->
						<!--</v-row>-->

						<!--</template>-->
						<!--</v-data-iterator>-->
			</div>

</template>

<script>
   /* eslint-disable */
   import {EventBus} from "../helpers/event-bus";
   import {mapGetters} from "vuex";
   import paginacao from '../helpers/paginacao'

   export default {
      mixins: [paginacao],
      name: "TableIterator",
      props: {
         cabecalho: {type: Array, default: () => []},
         itens: {type: Array, default: () => []},
         id: {default: 'tabela'},
         PorPage: {default: 10},
         totalPaginas: {default: 1},
         setPagina: {default: 1},
         showItemPerPag: {type: Boolean, default: true},
         dense: {type: Boolean, default: false},
         chave: {default: ''},
         hasheader: {type: Boolean, default: false},
         hideFooter: {type: Boolean, default: true},
         showAddButton: {type: Boolean, default: true},
         showContextMenu: {type: Boolean, default: false},
         disabledAddButton: {type: Boolean, default: false},
         pagSiple: {type: Boolean, default: false},
      },
      data: () => ({
         pageCount: 0,
         lista_cab: [],
         expanded: [],
         exp: '',
         loading: false,
         singleExpand: false,
         select: 10,
         page: 1,
         sortBy: '',
         sortDesc: false,
         itensPerPage: 10,
      }),
      methods: {
         validarCampo(campo) {
            if (campo !== undefined && campo !== null && campo !== '') {
               return true
            } else {
               return false
            }
         },
         isSortable(item) {
            if (item.hasOwnProperty('sortable')) {
               return item.sortable
            } else {
               return true
            }
         },
         setLoadingColor(cor) {
            return cor
         },
         setClasse(item) {
            var classe = 'text-left '

            if (this.validarCampo(item.align)) {
               classe = 'text-' + item.align + ' '
            }

            if (item.hasOwnProperty('sortable')) {
               classe += item.sortable ? 'sortable ' : ' '
            } else {
               classe += 'sortable '
            }
            return classe
         },
         ordenando(item, index) {
            if (this.isSortable(item)) {
               this.loading = true
               if (item.aria_sort === 'none') {
                  item.aria_sort = 'ascending'
                  item.icone = 'mdi-menu-up'

               } else if (item.aria_sort === 'ascending') {
                  item.aria_sort = 'descending'
                  item.icone = 'mdi-menu-down'
               } else if (item.aria_sort === 'descending') {
                  item.aria_sort = 'none'
                  item.icone = 'md-nada'
               }
            }
            this.lista_cab[index] = item

            this.ordenar(item.value, item.aria_sort)

            // var c = JSON.parse(JSON.stringify(item.classe))
            // if (this.isSortable(item)) {
            //   this.loading = true
            //   if (item.aria_sort === 'none') {
            //     item.aria_sort = 'ascending'
            //
            //     if (item.classe.match(/active desc/) !== null) {
            //       c = item.classe.replace('active desc', '')
            //     }
            //
            //     if (item.classe.match(/active asc/) === null) {
            //       c += 'active asc'
            //     }
            //   } else if (item.aria_sort === 'ascending') {
            //     item.aria_sort = 'descending'
            //     if (item.classe.match(/active asc/) !== null) {
            //       c = item.classe.replace('active asc', '')
            //     }
            //     if (item.classe.match(/active desc/) === null) {
            //       c += 'active desc'
            //     }
            //
            //   } else if (item.aria_sort === 'descending') {
            //     item.aria_sort = 'none'
            //
            //     if (item.classe.match(/active asc/) !== null) {
            //       c = item.classe.replace('active asc', '')
            //     }
            //     if (item.classe.match(/active desc/) !== null) {
            //       c = item.classe.replace('active desc', '')
            //     }
            //     c += ''
            //   }
            //   item.classe = c
            //
            //   this.lista_cab[index] = item
            //
            //   this.ordenar(item.value, item.aria_sort)
            // }
         },

         isshowContextMenu(event, item) {
            if (this.showContextMenu) {
               this.$emit('context', {event: event, item: item})
            } else {
               event.preventDefault()
            }
         },
         expandir(item, id) {
            if (this.singleExpand && this.expanded.length > 0) {
               this.expanded = this.exp === id ? [] : [item]
               this.singleExpand = this.exp === id
            } else if (this.singleExpand && this.expanded.length <= 0) {
               this.expanded = [item]
            } else if (!this.singleExpand && this.expanded.length > 0) {
               this.singleExpand = true
               this.expanded = [item]
            } else if (!this.singleExpand && this.expanded.length <= 0) {
               this.singleExpand = true
               this.expanded = [item]
            }
            this.exp = id
         },
         goToAdd() {
            this.$emit('novo')
            // EventBus.$emit('CLICK_BTN_ADD')
         },
         lista(item) {
            this.$emit('item', item)
         },
         updateItemsPerPage(number) {
            this.itensPerPage = number
            this.$emit('por_paginas', parseInt(number))
         },

         ordenar(id, ordem) {

            var direcao = 'asc'

            if (!this.header) {
               if (ordem !== 'none' && ordem !== null) {
                  direcao = ordem === 'ascending' ? 'asc' : 'desc'
                  var ordenar = {
                     campo: id,
                     direction: direcao
                  }
                  this.$emit('ordem', ordenar)
               }
            } else {
               var orde = {
                  campo: '',
                  direction: ''
               }

               if (ordem !== 'none' && ordem !== null) {
                  direcao = ordem === 'ascending' ? 'asc' : 'desc'
                  orde = {
                     campo: id,
                     direction: direcao
                  }
                  this.$emit('ordem', orde)
               } else {
                  orde = {
                     campo: id,
                     direction: 'asc'
                  }
                  this.$emit('ordem', orde)
               }
            }
         },
      },
      computed: {
         // ...mapGetters('configuracao', {tema: 'pegarTema'}),
         listaCabecalho() {
            var cabecalho = JSON.parse(JSON.stringify(this.cabecalho))
            for (var i = 0; i < cabecalho.length; i++) {
               cabecalho[i].classe = this.setClasse(cabecalho[i])
               cabecalho[i].icone = 'mdi-chevron-nada'
               cabecalho[i].aria_sort = 'none'
            }
            this.lista_cab = cabecalho
            // return cabecalho
         },

         // listaCabecalho() {
         //   var cabecalho = JSON.parse(JSON.stringify(this.cabecalho))
         //   for (var i = 0; i < cabecalho.length; i++) {
         //     cabecalho[i].classe = this.setClasse(cabecalho[i])
         //     cabecalho[i].icone = 'mdi-arrow-up'
         //     cabecalho[i].aria_sort = 'none'
         //   }
         //   this.lista_cab = cabecalho
         //   return cabecalho
         // },
         getTotalPagina() {
            return Math.ceil(this.itens.length / this.select)
         },
         ...mapGetters('geral', {search: 'getSearch'}),

      },
      watch: {
         select: function () {
            this.select = parseInt(this.select) <= 0 ? 1 : this.select
            this.page = 1
            if (!this.pagSiple) {
               if (this.select > 0) {
                  this.updateItemsPerPage(parseInt(this.select))
               } else {
                  this.updateItemsPerPage(5)
               }
            }


         },
         setPagina: function () {
            if (this.setPagina) {
               this.page = 1
            }
         },
         page: function () {
            this.page = isNaN(this.page) ? 0 : this.page
            if (this.hideFooter){
               this.loading = true
            }

            if (!this.pagSiple) {
               this.$emit('paginas', parseInt(this.page))
            } else {
            }

         },
      },
      created() {
      },
      mounted() {
         EventBus.$on('SHOW_LISTA', (sucesso) => {
            this.loading = false
         })

      },
      beforeDestroy() {
         EventBus.$off('SHOW_LISTA')
      },

   }
</script>


