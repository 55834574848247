/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class BannerTexto {
  constructor (banner_id) {
    this.banner_texto_id = uuidV4();
    this.banner_id= banner_id;
    this.texto = '';
    this.link= '';
    this.efeito = 'fade';
    this.posicao = 'start';
    this.alinhamento = 'left';
  }
}
