/* eslint-disable */
import {createStore} from 'vuex'
import geral from './modules/geral'

// import configuracao from './modules/configuracoes'
import usuario from './modules/usuarios'
import token from "./modules/token";
import blog from './modules/blog'
import convenio from './modules/convenios'
import tratamento from './modules/tratamento'
import banner from './modules/banner2'
import banner_control_modal from './modules/banner_control_modal'
import local_atendimento from './modules/local_atendimento'
import profissional from "./modules/profissional";
import dados_clinica from "./modules/dados_clinica";

export default createStore({
  modules: {
    token,
    blog,
    geral,
    banner,
    dados_clinica,
    tratamento,
    usuario,
    convenio,
    profissional,
    // instagram,
    // configuracao,
    local_atendimento,
    banner_control_modal
  }
})
